exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertising-index-js": () => import("./../../../src/pages/advertising/index.js" /* webpackChunkName: "component---src-pages-advertising-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-marketing-community-marketing-js": () => import("./../../../src/pages/digital-marketing/community-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-community-marketing-js" */),
  "component---src-pages-digital-marketing-email-marketing-js": () => import("./../../../src/pages/digital-marketing/email-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-email-marketing-js" */),
  "component---src-pages-digital-marketing-index-js": () => import("./../../../src/pages/digital-marketing/index.js" /* webpackChunkName: "component---src-pages-digital-marketing-index-js" */),
  "component---src-pages-digital-marketing-marketing-campaign-js": () => import("./../../../src/pages/digital-marketing/marketing-campaign.js" /* webpackChunkName: "component---src-pages-digital-marketing-marketing-campaign-js" */),
  "component---src-pages-ecommerce-optimization-customer-research-js": () => import("./../../../src/pages/ecommerce-optimization/customer-research.js" /* webpackChunkName: "component---src-pages-ecommerce-optimization-customer-research-js" */),
  "component---src-pages-ecommerce-optimization-digital-strategy-js": () => import("./../../../src/pages/ecommerce-optimization/digital-strategy.js" /* webpackChunkName: "component---src-pages-ecommerce-optimization-digital-strategy-js" */),
  "component---src-pages-ecommerce-optimization-index-js": () => import("./../../../src/pages/ecommerce-optimization/index.js" /* webpackChunkName: "component---src-pages-ecommerce-optimization-index-js" */),
  "component---src-pages-ecommerce-optimization-platform-building-js": () => import("./../../../src/pages/ecommerce-optimization/platform-building.js" /* webpackChunkName: "component---src-pages-ecommerce-optimization-platform-building-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-js": () => import("./../../../src/pages/join-us.js" /* webpackChunkName: "component---src-pages-join-us-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-website-strategy-design-js": () => import("./../../../src/pages/website-strategy/design.js" /* webpackChunkName: "component---src-pages-website-strategy-design-js" */),
  "component---src-pages-website-strategy-development-js": () => import("./../../../src/pages/website-strategy/development.js" /* webpackChunkName: "component---src-pages-website-strategy-development-js" */),
  "component---src-pages-website-strategy-index-js": () => import("./../../../src/pages/website-strategy/index.js" /* webpackChunkName: "component---src-pages-website-strategy-index-js" */),
  "component---src-pages-website-strategy-seo-js": () => import("./../../../src/pages/website-strategy/seo.js" /* webpackChunkName: "component---src-pages-website-strategy-seo-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

